define("discourse/plugins/discourse-private-topics/discourse/connectors/category-custom-security/private-topics", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service"], function (_exports, _component, _tracking, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let PrivateTopics = _exports.default = (_dec = (0, _object.computed)("site.groups.[]"), (_class = class PrivateTopics extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "site", _descriptor, this);
      _initializerDefineProperty(this, "siteSettings", _descriptor2, this);
      _initializerDefineProperty(this, "selectedGroups", _descriptor3, this);
      let groupNames = [];
      let groupIds = (this.args.outletArgs.category.custom_fields.private_topics_allowed_groups || "").split(",").filter(Boolean).map(id => parseInt(id, 10));
      this.site.groups.forEach(group => {
        if (groupIds.includes(parseInt(group.id, 10))) {
          groupNames.push(group.name);
        }
      });
      this.selectedGroups = groupNames;
    }
    get availableGroups() {
      return (this.site.groups || []).map(g => {
        // don't list "everyone"
        return g.id === 0 ? null : g.name;
      }).filter(Boolean);
    }
    onChangeGroups(groupNames) {
      this.selectedGroups = groupNames;
      // let groupNames = values.split(",").filter(Boolean);
      let groupIds = [];
      this.site.groups.forEach(group => {
        if (groupNames.includes(group.name)) {
          groupIds.push(group.id);
        }
      });
      this.args.outletArgs.category.custom_fields.private_topics_allowed_groups = groupIds.join(',');
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "selectedGroups", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "availableGroups", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "availableGroups"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeGroups", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeGroups"), _class.prototype)), _class));
  ;
});